<script lang="ts">
	let extraClass = '';
	export let id = '';
	export { extraClass as class };
</script>

<section {id} class="relative {extraClass} w-full">
	<slot name="background" />
	<div class="relative container xl:max-w-7xl px-4 md:px-8 lg:px-12">
		<slot />
	</div>
</section>
